import { useEffect, useRef, useState } from 'react'
import { isBrowser } from '../utils/generic'
import storageService from '../services/storageService'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import AboutYouIcon from '../images/icon_user.svg'
import AddressIcon from '../images/icon_address.svg'
import PhoneIcon from '../images/icon_phone.svg'
import VerificationIcon from '../images/icon_shield.svg'

const PROGRESS_STEPS = {
  ABOUT_YOU: {
    text: 'registration.progress.aboutYou',
    icon: AboutYouIcon,
  },
  PHONE: {
    text: 'registration.progress.phone',
    icon: PhoneIcon,
  },
  CONTACT: {
    text: 'registration.progress.contact',
    icon: AddressIcon,
  },
  ADDRESS: {
    text: 'registration.progress.address',
    icon: AddressIcon,
  },
  VERIFICATION: {
    text: 'registration.progress.verification',
    icon: VerificationIcon,
  },
}

const PROGRESS_STEPS_CONTACT = [
  PROGRESS_STEPS.ABOUT_YOU,
  PROGRESS_STEPS.CONTACT,
  PROGRESS_STEPS.VERIFICATION,
]

export const REGISTRATION_STEP_IDS = {
  EMAIL_AND_PASS: 'EMAIL_AND_PASS',
  SELFIE_AND_ID: 'SELFIE_AND_ID',
  ADDRESS: 'ADDRESS',
  ACCEPT_TC: 'ACCEPT_TC',
  CONFIRM_PHONE: 'CONFIRM_PHONE',
  CONFIRM_EMAIL: 'CONFIRM_EMAIL',
  CONFIRM_LIVENESS: 'CONFIRM_LIVENESS',
}

export const REGISTRATION_STEPS = [
  {
    id: REGISTRATION_STEP_IDS.EMAIL_AND_PASS,
    progress: 1,
    progressSteps: PROGRESS_STEPS_CONTACT,
    isStart: true,
    hideBackButton: true,
    showUspsBarDesktop: true,
    showUspsBarMobile: true,
    showBannerOnMobile: true,
  },
  {
    id: REGISTRATION_STEP_IDS.CONFIRM_EMAIL,
    progress: 1,
    progressSteps: PROGRESS_STEPS_CONTACT,
    showUspsBarDesktop: false,
    showUspsBarMobile: false,
  },
  {
    id: REGISTRATION_STEP_IDS.CONFIRM_PHONE,
    progress: 2,
    progressSteps: PROGRESS_STEPS_CONTACT,
    showUspsBarDesktop: false,
    showUspsBarMobile: false,
  },
  {
    id: REGISTRATION_STEP_IDS.ADDRESS,
    progress: 2,
    progressSteps: PROGRESS_STEPS_CONTACT,
    showUspsBarDesktop: false,
    showUspsBarMobile: false,
  },
  {
    id: REGISTRATION_STEP_IDS.SELFIE_AND_ID,
    progress: 3,
    progressSteps: PROGRESS_STEPS_CONTACT,
    showUspsBarDesktop: false,
    showUspsBarMobile: false,
  },
  {
    id: REGISTRATION_STEP_IDS.CONFIRM_LIVENESS,
    progress: 3,
    progressSteps: PROGRESS_STEPS_CONTACT,
    showUspsBarDesktop: false,
    showUspsBarMobile: false,
    onlyShowOnMigratable: true,
    showOnMigratableProp: 'livenessMissing',
  },
  {
    id: REGISTRATION_STEP_IDS.ACCEPT_TC,
    progress: 2,
    showUspsBarDesktop: false,
    showUspsBarMobile: false,
    onlyShowOnMigratable: true,
  },
]

export const useRegistrationSteps = () => {
  const useNewFlow = useFeatureIsOn('registration_new_flow')
  const [migratableData, setMigratableData] = useState()
  const migratableDataRef = useRef(migratableData)
  migratableDataRef.current = migratableData

  const getFirstIndex = () => {
    if (!useNewFlow) return 0
    return REGISTRATION_STEPS.findIndex(
      (step) => !step.isOldFlow && step.isStart
    )
  }

  const getLastIndex = () => {
    if (!useNewFlow) {
      return (
        REGISTRATION_STEPS.findIndex((step) => !step.isOldFlow) - 1 ??
        REGISTRATION_STEPS.length - 1
      )
    }

    const migratableData = migratableDataRef.current
    const isMigratable = !!migratableData
    for (let i = REGISTRATION_STEPS.length - 1; i >= 0; i--) {
      const { onlyShowOnMigratable, showOnMigratableProp } =
        REGISTRATION_STEPS[i]

      if (isMigratable) {
        if (!onlyShowOnMigratable && !migratableData[showOnMigratableProp]) {
          continue
        }
      } else if (onlyShowOnMigratable) {
        continue
      }

      return i
    }

    // If user got here something went weirdly wrong
    // we should block the registration temporarily
    return getFirstIndex() + 1
  }

  //* If you want to easily go to a specific formStep
  //* Uncomment the following line and change the number
  // const [formStep, setFormStep] = useState(0)
  const [formStep, setFormStep] = useState(getFirstIndex())
  const [isLastStep, setIsLastStep] = useState(false)

  const getShouldSkipStep = (stepIndex) => {
    const { showOnMigratableProp, isStart, onlyShowOnMigratable } =
      REGISTRATION_STEPS[stepIndex] ?? {}

    if (migratableDataRef.current) {
      if (isStart) return false
      if (showOnMigratableProp) {
        return !migratableDataRef.current[showOnMigratableProp]
      }
      return !onlyShowOnMigratable
    } else {
      return onlyShowOnMigratable
    }
  }

  const getPrevStepNum = (current) => {
    const newStep = current - 1
    const firstIndex = getFirstIndex()
    if (newStep <= firstIndex) return firstIndex

    if (getShouldSkipStep(newStep)) {
      return getPrevStepNum(newStep)
    }

    return newStep
  }

  const getNextStepNum = (current) => {
    const newStep = current + 1
    const lastIndex = getLastIndex()
    if (newStep >= lastIndex) return lastIndex

    if (getShouldSkipStep(newStep)) return getNextStepNum(newStep)

    return newStep
  }

  const goBack = () => setFormStep(getPrevStepNum)
  const goToNextStep = () => setFormStep(getNextStepNum)

  useEffect(() => {
    const lastIndex = getLastIndex()
    if (isLastStep && formStep !== lastIndex) {
      setIsLastStep(false)
    } else if (!isLastStep && formStep === lastIndex) {
      setIsLastStep(true)
    }
  }, [isLastStep, formStep])

  const scrollToTop = () => {
    const registerModal = document.getElementById('register-modal')
    let modalElem = registerModal?.parentElement?.parentElement
    if (!modalElem) modalElem = window

    const formElem = document.getElementById('register-form')
    formElem.scrollTo({ top: 0 })
    modalElem.scrollTo({ top: 0 })
  }

  useEffect(() => {
    if (!isBrowser()) return
    storageService.setValue('lastFormStep', formStep)
    scrollToTop()
  }, [formStep])

  return {
    formStep,
    isLastStep,
    goToNextStep,
    goBack,
    migratableData,
    setMigratableData,
  }
}
