import React, { useContext, useEffect, useState } from 'react'

import { Button } from '../../../atomic-components/atoms/button'
import { Modal } from '../../../atomic-components/atoms/modal'

import { Content, StyledSelectBankWrapper, Wrapper } from './styles'
import { useCreateBankAccountContext } from '../../../context/createBankAccountProvider'

import { BankDetailsForm } from '../../molecules/bankDetailsForm'
import { AuthContext } from '../../../context/authProvider'
import { checkFormFields, getBrazilianTopBanks } from './utils'
import { CAFSteps } from '../CAFSteps'
import {
  createBankAccountV2,
  deleteBankAccount,
  getLivenessBankAccount,
  requestLivenessProcess,
} from '../../../adapters/payments'
import { If } from '../../atoms/if'
import { usePaymentDrawerContext } from '../../../context/paymentDrawerProvider'
import { useSessionStorage } from '../../../hooks/useSessionStorage'
import { cookiePrefix } from '../../../utils/cookies'
import { ImageWrapper, Message, Title } from '../CAFSteps/styles'
import { Image } from '../../atoms/image'

import lockIcon from '../../../images/icon_lock_gray.svg'
import { getBankAccountFormFields } from '../../molecules/bankAccounts/formFields'
import { toast } from '../../atoms'
import { SearchableSelect } from '../SearchableSelect'
import { getBrazilianBanks } from '../../../adapters/common'
import { AddBankAccountHeader } from '../../molecules/AddBankAccountHeader'

const stepTypes = {
  BANK_SELECT: 0,
  BANK_DETAILS: 1,
  VERIFICATION: 2,
  DEPOSIT: 3,
  CLOSE_CONFIRMATION: 4,
}

const AddBankAccountModal = ({ hideOverlay, isMobile, translate }) => {
  const { isOpen, closeCreateBankAccount } = useCreateBankAccountContext()
  const { isLoggedIn, user } = useContext(AuthContext)
  const { open: openDepositDrawer } = usePaymentDrawerContext()
  const { getSessionValue, removeSessionValue } = useSessionStorage()

  const [creationStep, setCreationStep] = useState(0)
  const [enableNextButton, setEnableNextButton] = useState(false)
  const [bankDetails, setBankDetails] = useState({
    accountType: 'CRN',

  })
  const [livenessData, setLivenessData] = useState('')
  const [isWaitingVerification, setIsWaitingVerification] = useState(false)
  const [verificationStarted, setVerificationStarted] = useState(false)
  const [isRejected, setIsRejected] = useState(false)
  const [confirmingClose, setConfirmingClose] = useState(false)
  const [waitingLivenessAccount, setWaitingLivenessAccount] = useState(null)
  const [formDataFields, setFormDataFields] = useState([])

  const [brazilianBankOptions, setBrazilianBankOptions] = useState([])
  const [selectedBank, setSelectedBank] = useState(null)
  const [actualStep, setActualStep] = useState(null)

  const handleModalClose = () => {
    if(creationStep === stepTypes.BANK_DETAILS) {
      setFormDataFields([])
    }

    if (creationStep !== stepTypes.VERIFICATION) {
      setActualStep(creationStep)
      setCreationStep(stepTypes.CLOSE_CONFIRMATION)
    }

    setConfirmingClose(true)
  }

  const handleNextStep = async () => {
    if(creationStep + 1 === stepTypes.VERIFICATION) {
      if (typeof window !== 'undefined') {
        window.dataLayer.push({
          event: 'ba_liveness_page_viewed',
        })
      }

      if(livenessData) {
        setCreationStep(creationStep + 1)
        return
      }

      getLivenessData()
    }

    setCreationStep(creationStep + 1)
  }

  const handlePreviousStep = () => {
    if(creationStep === stepTypes.BANK_DETAILS) {
      setFormDataFields([])
    }

    setCreationStep(creationStep - 1)
  }

  const getLivenessData = async () => {
    const {
      data: { link, onboardingId },
    } = await requestLivenessProcess()

    const formFields = await getBankAccountFormFields(translate)
    setFormDataFields(formFields)

    setLivenessData({
      url: link,
      onboardingId,
    })
  }

  const getBankOptions = async () => {
    const brazilianBanks = (
      await getBrazilianBanks()
    ).data.data.sort((a, b) =>
      a.name.localeCompare(b.name)
    )

    brazilianBanks.forEach((bank) => {
      const name = bank.name.split(' - ')[1]
      const code = bank.name.split(' - ')[0]
      const fullName = bank.name

      bank.fullName = fullName
      bank.name = name
      bank.code = code
    })

    setBrazilianBankOptions(brazilianBanks)

    return brazilianBanks
  }

  const getInitialData = async () => {
    await getBankOptions()
  }

  const handleBankAccountCreation = async () => {
    if(waitingLivenessAccount) {
      removeSessionValue(`${cookiePrefix}pendingLivenessAccount`)

      toast.success(translate('account.deposit.bankAccountCreated'))
      window.postMessage('bankAccountCreated', '*')
      closeCreateBankAccount()
      openDepositDrawer()

      return
    }

    const model = {
      ...bankDetails,
      onboardingId: livenessData.onboardingId,
    }

    const { ok } = await createBankAccountV2(model)

    if (!ok) {
      return
    }

    if (typeof window !== 'undefined') {
      window.dataLayer.push({
        event: 'ba_registration_successful',
      })
    }

    toast.success(translate('account.deposit.bankAccountCreated'))
    window.postMessage('bankAccountCreated', '*')
    closeCreateBankAccount()
    openDepositDrawer()
  }

  const handleCheckWaitingLivenessAccount = () => {
    const pendingbankAccount = getSessionValue(
      `${cookiePrefix}pendingLivenessAccount`
    )

    if (pendingbankAccount) {
      setWaitingLivenessAccount(pendingbankAccount)
    }
  }

  const handleSavePreviousAccount = async () => {
    const { 
      data: { url, onboardingId }
     } = await getLivenessBankAccount(waitingLivenessAccount.id)

    setLivenessData({
      url,
      onboardingId
    })

    setCreationStep(stepTypes.VERIFICATION)
  }

  const handleDeletePreviousAccount = async () => {
    const response = await deleteBankAccount(waitingLivenessAccount.id)

    if (!response.ok) {
      return toast.error(response.error.message)
    }

    removeSessionValue(`${cookiePrefix}pendingLivenessAccount`)
    toast.success(translate('addBankAccount.formDeleteAccountMessage'))
    window.postMessage('bankAccountCreated', '*')
    closeCreateBankAccount()
  }

  const handleSelectBank = (bank) => {
    setSelectedBank(bank)
    setBankDetails({ ...bankDetails, bankId: bank.id })
    setCreationStep(stepTypes.BANK_DETAILS)
  }

  useEffect(() => {
    if (!isLoggedIn) return

    getInitialData()
    handleCheckWaitingLivenessAccount()
  }, [translate, isLoggedIn])

  useEffect(() => {
    if (creationStep !== stepTypes.BANK_DETAILS) {
      return
    }

    const enableButton = checkFormFields(bankDetails)
    setEnableNextButton(enableButton)
  }, [bankDetails])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.dataLayer.push({
        event: 'ba_creation_page_viewed',
      })
    }
  }, [])

  useEffect(() => {
    if (creationStep !== stepTypes.BANK_DETAILS) {
      return
    }

    getBankAccountFormFields(translate).then((fields) => {
      fields[0][0].defaultValue = selectedBank?.name
      fields[0][0].labelButton = 
      <button onClick={handlePreviousStep}>{
        translate('addBankAccount.changeBank')
      }</button>

      setFormDataFields(fields)
    })
  }, [creationStep])

  const createCloseConfirmationContent = () => (
    <>
      <Title isBankAccountProcess={true}>
        {translate('addBankAccount.CloseVerificationTitle')}
      </Title>

      <ImageWrapper>
        <Image url={lockIcon} className="main-img" />
      </ImageWrapper>

      <Message>{translate('addBankAccount.CloseVerificationText')}</Message>
      <Button
        expand
        style={{ 'margin-top': '2rem' }}
        onClick={() => {
          setCreationStep(actualStep)
        }}
      >
        {translate('addBankAccount.CloseVerificationReturn')}
      </Button>
      <Button
        expand
        bordered
        dark
        style={{ 'margin-top': '1rem' }}
        onClick={closeCreateBankAccount}
      >
        {translate('addBankAccount.CloseVerificationClose')}
      </Button>
    </>
  )

  const getStepContent = {
    0: () => (
      <StyledSelectBankWrapper>
        <AddBankAccountHeader 
          pageTitle={translate('addBankAccount.SelectBankTitle')}
          pageSubtitle={translate('addBankAccount.SelectBankSubTitle')}
        />

        <SearchableSelect
          options={brazilianBankOptions}
          initialList={getBrazilianTopBanks(brazilianBankOptions)}
          onSelect={(bank) => handleSelectBank(bank)}
          translate={translate}  
        />
      </StyledSelectBankWrapper>
    ),
    1: () => (
      <BankDetailsForm
        formFields={formDataFields}
        setBankDetails={setBankDetails}
        bankDetails={bankDetails}
        translate={translate}
        waitingLivenessAccount={waitingLivenessAccount}
        user={user}
      />
    ),
    2: () => {
      if (!livenessData) return <></>

      return (
        <CAFSteps
          cafIframeUrl={livenessData.url}
          confirmBeforeClosing={true}
          isWaitingVerification={isWaitingVerification}
          setIsWaitingVerification={setIsWaitingVerification}
          idVerificationStarted={verificationStarted}
          setIdVerificationStarted={setVerificationStarted}
          showRejected={isRejected}
          onStartVerification={() => setIsRejected(false)}
          onClose={closeCreateBankAccount}
          closeCafIFrame={handleBankAccountCreation}
          shouldUpdateUser={false}
          isBankAccountProcess
          confirmingClose={confirmingClose}
          setConfirmingClose={setConfirmingClose}
          waitingAltDescription={translate(
            'addBankAccount.verificationAwaitDescription'
          )}
          startAltDescription={translate(
            'addBankAccount.verificationStartDescription'
          )}
          closeAltDescription={translate('addBankAccount.CloseVerificationText')}
          closeAltButtonText={translate('addBankAccount.CloseVerificationReturn')}
        />
      )
    },
    3: null,
    4: createCloseConfirmationContent,
  }

  return (
    <Modal
      theme="bank-account"
      isOpen={isOpen}
      onClose={handleModalClose}
      hideOverlay={hideOverlay ? hideOverlay : null}
      isMobile={isMobile ? isMobile : null}
      title={translate('addBankAccount.title')}
      onBack={handlePreviousStep}
      hideBackButton={
        creationStep === stepTypes.BANK_SELECT || 
        creationStep === stepTypes.CLOSE_CONFIRMATION
      }
      isWhiteBackground
    >
      <Wrapper>
        <Content>
          {getStepContent[creationStep]()}

          <If
            condition={
              creationStep === stepTypes.BANK_DETAILS && 
              !waitingLivenessAccount
            }
            render={() => (
              <Button
                expand
                style={{
                  'margin-top': '2rem',
                  'max-width': '500px',
                }}
                disabled={!enableNextButton}
                onClick={handleNextStep}
              >
                {translate('addBankAccount.nextButton')}
              </Button>
            )}
          />

          <If
            condition={
              creationStep === stepTypes.BANK_DETAILS && 
              waitingLivenessAccount
            }
            render={() => (
              <>
                <Button
                  expand
                  style={{
                    'margin-top': '2rem',
                    'max-width': '500px',
                  }}
                  onClick={handleSavePreviousAccount}
                >
                  {translate('addBankAccount.formSaveAccountLiveness')}
                </Button>

                <Button
                  expand
                  outlineColor="black"
                  textColor="black"	
                  style={{
                    'margin-top': '1rem',
                    'max-width': '500px',
                  }}
                  onClick={handleDeletePreviousAccount}
                >
                  {translate('addBankAccount.formForgetAccountLiveness')}
                </Button>
              </>
            )}
          />
        </Content>
      </Wrapper>
    </Modal>
  )
}

export default AddBankAccountModal
