import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'

import { Button } from '../../atoms/button'
import { Flex } from '../../atoms/flex'
import { Title, Text } from '../../atoms/text'
import { toast } from '../../atoms/toast'
import { ValueInput } from '../../atoms/valueInput'
import { useModalContext } from '../../../context/modalProvider'
import { useTranslation } from '../../../context/translationProvider'
import { Field } from '../field'
import { FieldDropdown } from '../fieldDropdown'
import { StyledFormModalWrapper, StyledTextWrapper } from './styles'
import { If } from '../../atoms/if'

const DefaultFormFields = [
  {
    name: 'textInput',
    type: 'text',
    label: 'Input text',
    placeholder: 'Add some text please',
    maxLength: 15,
  },
  {
    name: 'dropdownExample',
    type: 'dropdown',
    label: 'Dropdown selection',
    placeholder: 'Select an option',
    options: [
      { value: 'OPT_1', label: 'Option 1' },
      { value: 'OPT_2', label: 'Option 2' },
    ],
  },
]

const FormModal = (props) => {
  const { translate } = useTranslation()
  const { close } = useModalContext()
  const {
    formFields = props.formFields || DefaultFormFields,
    formStyleWrapper: FormStyleWrapper = null,
    onCancel,
    onCancelText,
    onSave,
    onSaveText,
    saveForm,
    subtitle,
    text,
    isOnlyForm,
    customSetData,
    customData,
    isAllFieldsRequired,
  } = props

  const formProperties = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  })
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
  } = formProperties

  const initializeFormData = () => {
    const initialData = {}

    if (Array.isArray(formFields)) {
      formFields.forEach((section) => {
        section.forEach((field) => {
          if (field.type === 'dropdown' && field.options.length > 0) {
            initialData[field.name] = field.options[0].value
            setValue(field.name, field.options[0].value)
          }
        })
      })

      return initialData
    }

    formFields.forEach((field) => {
      if (field.type === 'dropdown' && field.options.length > 0) {
        initialData[field.name] = field.options[0].value
        setValue(field.name, field.options[0].value)
      }
    })
    return initialData
  }

  const [formData, setFormData] = useState(initializeFormData)
  const [loading, setLoading] = useState(false)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(isAllFieldsRequired)

  const handleInputChange = (name, value, maskFunction, e) => {
    let formValue = value

    if (maskFunction && value) {
      const { value: rawValue, maskedValue } = maskFunction(value)

      formValue = rawValue
      e.target.value = maskedValue
    }

    if (customSetData) {
      customSetData({
        ...customData,
        ...formData,
        [name]: formValue,
      })
    }

    setFormData({
      ...formData,
      [name]: formValue,
    })
  }

  const handleReturnMaskedDefaultValue = (field, value) => {
    if (!value) return value

    if (field && field.maskFunction) {
      return field.maskFunction(value).maskedValue
    }

    return value
  }

  const handleSave = formProperties.handleSubmit(async () => {
    setLoading(true)

    try {
      const response = await saveForm(formData)
      if (response.ok) {
        onSave?.(response.data)
        close()
      } else {
        toast.error(translate('common.unexpectedError'))
      }
    } catch (error) {
      toast.error(translate('common.unexpectedError'))
    } finally {
      setLoading(false)
    }
  })

  const renderInputField = (field) => {
    switch (field.type) {
      case 'text':
        return (
          <Field
            formProperties={register(field.name, { required: true })}
            label={field.label}
            key={field.name}
            placeholder={field.placeholder}
            errors={errors}
            onChange={(e) =>
              handleInputChange(
                field.name,
                e.target.value,
                field.maskFunction,
                e
              )
            }
            defaultValue={handleReturnMaskedDefaultValue(
              field,
              (field.defaultValue || customData[field.name] || null)
            )}
            showErrorMsg
            disabled={loading || field.disabled}
            minLength={field.minLength}
            maxLength={field.maxLength}
            mask={field.mask}
            labelButton={field.labelButton}
          />
        )
      case 'number':
        return (
          <ValueInput
            currencySymbol={field.currencySymbol}
            key={field.name}
            onChange={(value) => handleInputChange(field.name, value)}
            placeholder={field.placeholder}
            errors={errors}
            disabled={loading}
            minLength={field.minLength}
            maxLength={field.maxLength}
          />
        )
      case 'dropdown':
        return (
          <FieldDropdown
            fieldValue={getValues(field.name) || customData[field.name]}
            formProperties={register(field.name, { required: true })}
            key={field.name}
            label={field.label}
            onChange={(e) => handleInputChange(field.name, e.target.value)}
            removeFirstOption={true}
            values={field.options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
            errors={errors[field.name]}
            disabled={loading}
          />
        )
      default:
        return null
    }
  }

  const renderArrayFields = (formFields) => {
    return formFields.map((field, index) =>
      Array.isArray(field) ? (
        <div className="row-fields" key={`row-${index}`}>
          {field.map((item) => renderInputField(item))}
        </div>
      ) : (
        renderInputField(field)
      )
    )
  }

  useEffect(() => {
    if (!isAllFieldsRequired) return

    const { isDirty, isValid } = formProperties.formState
    const isAllFieldsFilled = Object.keys(formData).every(
      (key) => formData[key]
    )
    const hasErrors = Object.keys(errors).length > 0

    setIsSubmitDisabled(!isDirty || !isValid || !isAllFieldsFilled || hasErrors)
  }, [formData])

  if (isOnlyForm) {
    return (
      <form onSubmit={handleSave}>
        <If
          condition={FormStyleWrapper}
          render={() => (
            <FormStyleWrapper>{renderArrayFields(formFields)}</FormStyleWrapper>
          )}
          renderElse={renderArrayFields(formFields)}
        />
      </form>
    )
  }

  return (
    <StyledFormModalWrapper>
      <StyledTextWrapper>
        {subtitle && <Title level={2}>{subtitle}</Title>}
        {text && <Text>{text}</Text>}
      </StyledTextWrapper>

      <form onSubmit={handleSave}>
        <If
          condition={FormStyleWrapper}
          render={() => (
            <FormStyleWrapper>{renderArrayFields(formFields)}</FormStyleWrapper>
          )}
          renderElse={renderArrayFields(formFields)}
        />

        <Flex
          className="buttons-wrapper"
          direction="column"
          gap="1.5em"
          style={{ marginTop: '2.5em' }}
        >
          <Button type="submit" disabled={isSubmitDisabled || loading}>
            {onSaveText || translate('common.save')}
          </Button>
          <Button
            type="button"
            expand
            bordered
            dark
            onClick={(e) => {
              onCancel?.(e)
              close()
            }}
            disabled={loading}
          >
            {onCancelText || translate('commonCancel')}
          </Button>
        </Flex>
      </form>
    </StyledFormModalWrapper>
  )
}

FormModal.propTypes = {
  onSave: PropTypes.func,
  onSaveText: PropTypes.string,
  onCancel: PropTypes.func,
  onCancelText: PropTypes.string,
  saveForm: PropTypes.func,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  formStyleWrapper: PropTypes.elementType,
  formFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      label: PropTypes.string,
      placeholder: PropTypes.string,
      currencySymbol: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
  isOnlyForm: PropTypes.bool,
  customSetData: PropTypes.func,
  isAllFieldsRequired: PropTypes.bool,
}

export { FormModal }
