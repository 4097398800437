export const checkFormFields = (formFields) => {
  const formKeys = Object.keys(formFields)
  const hasEveryField = formKeys.every((field) => formFields[field])

  const hasMinimumAccountNumberLength = formFields?.accountNumber?.length >= 4
  const hasMinimumBankBranchLength = formFields?.bankBranch?.length >= 4

  return (
    hasEveryField &&
    formKeys.length > 3 &&
    hasMinimumAccountNumberLength &&
    hasMinimumBankBranchLength
  )
}

export const getBrazilianTopBanks = (banks) => {
  const topBanks = [
    'NU PAGAMENTOS',
    'CAIXA ECONOMICA FEDERAL',
    'BCO SANTANDER (BRASIL) S.A.',
    'BCO BRADESCO S.A.',
    'ITAÚ UNIBANCO S.A.',
    'BANCO INTER',
    'PICPAY',
    'BCO DO BRASIL S.A.',
    'MERCADO PAGO IP LTDA.',
    'BCO DO ESTADO DO RS S.A.',
    'BCO C6 S.A.',
    'NEON PAGAMENTOS S.A. IP',
    'BANCO PAN',
    'BCO ITAUCARD S.A.',
  ]

  return topBanks.map(
    (bankName) => banks.find((bank) => bank.name === bankName)
  )
}