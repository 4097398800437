import React from 'react'
import { FormModal } from '../formModal'
import { FormStyleWrapper } from '../bankAccounts/formFieldsStyles'
import { StyledCardWrapper } from './styles'
import { MyAccountBankAccountCard } from '../MyAccountBankAccountCard'
import { AddBankAccountHeader } from '../AddBankAccountHeader'

export const BankDetailsForm = ({
  formFields,
  setBankDetails,
  bankDetails,
  translate,
  waitingLivenessAccount,
  user,
}) => {
  const isWaitingLivenessProcess = !!waitingLivenessAccount

  const pageTitle = isWaitingLivenessProcess
    ? 'addBankAccount.formTitleLiveness'
    : 'addBankAccount.formTitle'

  const pageSubtitle = isWaitingLivenessProcess
    ? 'addBankAccount.formSubtitleLiveness'
    : 'addBankAccount.formSubtitle'

  return (
    <>
      <AddBankAccountHeader 
        pageTitle={translate(pageTitle).replace(
          '{NAME}',
          user.first_name
        )}
        pageSubtitle={translate(pageSubtitle)}
      />

      {isWaitingLivenessProcess ? (
        <StyledCardWrapper>
          <MyAccountBankAccountCard
            bankAccount={waitingLivenessAccount}
            tagLabel={translate('payments.bankAccountKeys.tags.accounts')}
            onDelete={() => {}}
            onUpdateDefault={() => {}}
            showOnly
          />
        </StyledCardWrapper>
      ) : (
        <FormModal
          isOnlyForm
          formFields={formFields}
          formStyleWrapper={FormStyleWrapper}
          customSetData={setBankDetails}
          customData={bankDetails}
        />
      )}
    </>
  )
}
